<template>
	<div id="warper">
		<el-image :src="require('@/assets/img/title_xq.png')" style="width: 100%;height:288px;"></el-image>
		<div class="desTitle">{{info.course_name}}</div>
		<div class="title">
			<div style="margin-left: 41px;">
				<div class="left">
					<div class="price">￥{{info.imoney}}</div>
					<!-- 					<div style="margin-left: 26px;margin-right: 20px;font-size: 14px;color: #bbb;">|</div>
 -->
					<!-- 					<div class="text">支付保障·课前随时退·课后有回放</div>
 -->
				</div>
				<!-- 				<div class="right">难度 中级 · 时长 22小时 · 学习人数 2303 · 综合评分 9.98</div>
 -->
			</div>
			<div class="signUpnow" @click="gobm">立即报名</div>
		</div>

		<el-card class="center">
			<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="用户管理" name="first"></el-tab-pane>
				<el-tab-pane label="配置管理" name="second"></el-tab-pane>
			</el-tabs> -->
			<div class="title_jn" v-if="info.spec.length>0">报考岗位选择</div>
			<div class="xmjs" v-if="info.spec.length>0">
				<div class="spec-list">
					<div :class="['spec-item',(spec==item.title?'action':'')]" v-for="(item, post) in info.spec"
						:key="post" @click="btnSpec(item)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="title_jn" v-if="info.leverid==1">课程介绍</div>
			<div class="title_jn" v-else>岗位介绍</div>
			<div v-html="return2Br(info.detailed)"></div>
			<div class="title_jn" v-if="info.leverid==1">课程详情</div>
			<div class="title_jn" v-else>岗位详情</div>
			<div v-html="return2Br(info.content)"></div>
			<div class="fujian" v-if="info.appendix && info.appendix.length > 0">
				附件下载：
				<div class="fujian-li">
					<a v-for="(item, index) in info.appendix" :href="defimgurl + item.files"
						:key="index">{{ item.title }}</a>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getCourseDetail
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				activeName: 'second',
				defimgurl: global.baseimgurl,
				info: {},
				userinfo: "",
				userType: 2,
				spec: "",
				money: 0,
			};
		},
		created() {
			var id = this.$route.query.id;
			if (!isnull(id)) {
				this.getCourseDetail(id);
			}
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			if (this.userinfo) {
				this.userType = this.userinfo.userType;
			}
		},
		methods: {
			return2Br(str) {
				if (!isnull(str)) {
					return str.replace(/\r?\n/g, "<br />");
				}
				return str;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			gobm() {
				if (this.userinfo && this.userType == 1) {
					if (this.info.leverid == 1)
						this.goto('/registrationUserInformation', {
							id: this.info.id,
							money: this.money,
							spec: this.spec
						}, 1);
					else
						this.goto('/signUp', {
							id: this.info.id,
							money: this.money,
							spec: this.spec
						}, 1);
				} else {
					this.resolvewin("/login", {
						type: 1,
						isback: 1
					}, 1);
				}
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			btnSpec(item) {
				console.log(item)
				this.spec = item.title;
				this.money = item.value;
				this.info.imoney = item.value;
			},
			getCourseDetail(id) {
				var _this = this;
				var par = {
					id: id
				};
				getCourseDetail(par).then((res) => {
					if (res.success) {
						_this.info = res.data[0];
						_this.info.spec = JSON.parse(_this.info.spec)
						if (_this.info.appendix) {
							_this.info.appendix = JSON.parse(_this.info.appendix);
						}
					}
				})
			}
		},
	};
</script>


<style lang="scss" scoped>
	#warper {
		text-align: center;
		position: relative;
		padding-bottom: 25px;
	}

	.desTitle {
		width: 1000px;
		position: absolute;
		font-size: 48px;
		left: 50%;
		margin-left: -500px;
		top: 50px;
		z-index: 10;
		color: #fff
	}

	.title {
		width: 1000px;
		height: 120px;
		line-height: 20px;
		border-radius: 12px;
		background-color: #fff;
		font-size: 14px;
		text-align: center;
		box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
		font-family: Arial;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		left: 50%;
		margin-left: -500px;
		z-index: 9;
		top: 228px;


		.left {
			display: flex;

			.price {
				color: #F90E0E;
				font-size: 28px;
				text-align: left;
				font-family: SourceHanSansSC-medium;
			}

			.text {
				color: #101010;
				font-size: 14px;
				text-align: left;
				font-family: SourceHanSansSC-regular;
			}
		}

		.right {
			color: #898989;
			font-size: 14px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
			margin-top: 10px;
		}

		.signUpnow {
			width: 140px;
			height: 48px;
			line-height: 48px;
			border-radius: 24px;
			background-color: #FC8060;
			color: #fff;
			cursor: pointer;
			font-size: 18px;
			text-align: center;
			font-family: Microsoft Yahei;
			margin-right: 23px;
		}
	}

	.center {
		// position: absolute;
		// left: 50%;
		// margin-left: -500px;

		margin: 0 auto;
		top: 370px;
		width: 1000px;
		// height: 120px;
		line-height: 20px;
		border-radius: 12px;
		background-color: #fff;
		margin-top: 81px;
		text-align: left;

		::v-deep .el-tabs__nav-wrap::after {
			background-color: #fff !important;
		}

		::v-deep .el-tabs__item {
			font-size: 16px;
		}

		.title_jn {
			color: #1A1A1A;
			font-size: 20px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
			margin-top: 32px;
			font-weight: bold;
		}

		.text {
			color: #898989;
			font-size: 14px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
			margin-top: 8px;
			display: flex;
			align-items: center;

		}

		.lecturer {
			width: 936px;
			height: 180px;
			line-height: 20px;
			border-radius: 8px;
			background-color: #F5F7FA;
			text-align: center;
			margin-top: 33px;

			.tx_img {
				width: 60px;
				height: 60px;
				border-radius: 30px;
				margin-top: 27px;
				margin-left: 35px;
			}
		}
	}

	.xmjs {
		width: 100%;
		/* height: 395px; */
		line-height: 20px;
		background-color: #fff;
		text-align: left;
		margin-top: 15px;
		padding: 5px 0;
	}

	.js_title {
		color: #101010;
		font-size: 16px;
		font-weight: bold;
		text-align: left;
		font-family: PingFangSC-regular;
		display: flex;
		align-items: center;
		margin-left: 15px;
	}

	.spec-list {}

	.spec-item {
		border: 1px solid;
		margin: 5px;
		padding: 0 15px;
		line-height: 30px;
		display: inline-block;
		cursor: pointer;
	}

	.spec-item.action {
		background-color: blue;
		color: #fff;
	}
</style>
